import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MUIContainer from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export const Wrapper = styled(Box)(({ theme }) => ({
	backgroundColor: theme.colors.background,
	padding: `${theme.spacing(1)} 0`,
	width: '100%',
}));

export const Container = styled(MUIContainer)(({ theme }) => ({
	padding: 0,

	[theme.breakpoints.down('md')]: {
		padding: 0,
	},
	[theme.breakpoints.up('md')]: {
		padding: 0,
	},
}));

export const ButtonContainer = styled(Grid)(({ theme }) => ({
	justifyContent: 'flex-end',

	[theme.breakpoints.up('md')]: {
		justifyContent: 'flex-end',
	},
}));
