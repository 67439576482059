import makeHomepage from '@main/factories/pages/makeHomepage';
import { useData } from 'vike-react/useData';

import type { Data } from './+data';

export function Page() {
	const { collections, dailyPuzzle } = useData<Data>();

	return makeHomepage(collections, dailyPuzzle);
}
