import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

export const Wrapper = styled('div')(({ theme }) => ({
	width: '100%',
	padding: `${theme.padding.big}px 0`,
	background: theme.colors.darkerBackground,
	marginTop: theme.spacing(3),
}));

export const DownloadButton = styled('img')(({ theme }) => ({
	display: 'block',
	marginBottom: theme.padding.normal,
}));

export const BottomNavigationLink = styled(Link)(({ theme }) => ({
	display: 'block',
	fontSize: 16,
	fontWeight: 400,
	textDecoration: 'none',
	marginBottom: theme.padding.normal,
}));

export const Paragraph = styled(Typography)(({ theme }) => ({
	marginBottom: theme.padding.big,
	textAlign: 'center',
}));

export const Title = styled(Typography)(({ theme }) => ({
	marginBottom: theme.padding.big,
}));

export const FooterNavigationContainer = styled(Grid)(({ theme }) => ({
	paddingBottom: theme.padding.big,
	borderBottom: `1px solid ${theme.colors.border}`,
}));

export const FooterCopyrightContainer = styled(Grid)(({ theme }) => ({
	paddingTop: theme.padding.big,
}));
