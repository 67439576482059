import { firestore } from '@infrastructure/firebase';
import {
	collection,
	query,
	where,
	orderBy,
	limit,
	getDocs,
	startAfter,
	getCountFromServer,
	doc,
	getDoc,
} from 'firebase/firestore';
import type { ICollectionsRepository } from '@domain/usecases/collections/CollectionsRepository';
import type { CollectionDto } from '@infrastructure/firebase/firestore/dto/CollectionDto';
import { CollectionSortField } from '@domain/usecases/collections/CollectionSortField';
import type { CollectionModel } from '@domain/models/CollectionModel';
import type { CollectionOrderBy } from '@domain/usecases/collections/CollectionOrderBy';

export class FirestoreCollectionRepository implements ICollectionsRepository {
	private readonly collectionRef;

	constructor() {
		this.collectionRef = collection(firestore, `/collections`);
	}

	async countActive(): Promise<number> {
		const queryRef = query(this.collectionRef, where('numberOfPuzzles', '>', 0));

		const ref = await getCountFromServer(queryRef);

		return ref.data().count;
	}

	async getById(id: string): Promise<CollectionModel | null> {
		const document = await getDoc(doc(firestore, `/collections`, id));

		if (!document.exists()) {
			return null;
		}

		const data = document.data() as CollectionDto;

		return {
			id: document.id,
			name: data.name,
			numberOfPuzzles: data.numberOfPuzzles,
			imageSrc: await this.getImageForCollection(document.id),
		};
	}

	async getNotEmptyCollections(options: {
		limit: number;
		order?: CollectionOrderBy;
		last?: string;
	}): Promise<CollectionModel[]> {
		let lastRef;
		if (options.last) {
			lastRef = await getDoc(doc(firestore, `/collections`, options.last));
		}

		const queryRef = query(
			this.collectionRef,
			where('numberOfPuzzles', '>', 0),
			orderBy(options.order?.field ?? CollectionSortField.name, options.order?.direction ?? 'desc'),
			...(lastRef ? [startAfter(lastRef)] : []),
			limit(options.limit)
		);

		const querySnapshot = await getDocs(queryRef);
		return Promise.all(
			querySnapshot.docs.map(async (document: any) => {
				const data = document.data() as CollectionDto;

				return {
					id: document.id,
					name: data.name,
					numberOfPuzzles: data.numberOfPuzzles,
					imageSrc: await this.getImageForCollection(document.id),
				};
			})
		);
	}

	public async getImageForCollection(id: string, offset = 0): Promise<string> {
		const queryRef = query(
			collection(firestore, `/collections/${id}/puzzles`),
			orderBy('createdAt', 'desc'),
			limit(offset + 1)
		);
		const querySnapshot = await getDocs(queryRef);

		return querySnapshot.docs[offset]?.data()?.iconUrl || '';
	}
}
