import React from 'react';
import Home from '@presentation/pages/Home';
import DefaultLayout from '@presentation/layouts/DefaultLayout';
import type { HomepageCategoryItem } from '@domain/usecases/config/ConfigRepository';
import type { ContentItemWithAuthorModel } from '@domain/models';

import { makeHomepageService } from '../makeHomepageService';

const makeHomepage = (collections: HomepageCategoryItem[], dailyPuzzle: ContentItemWithAuthorModel | null = null) => {
	const homepageService = makeHomepageService();

	return (
		<DefaultLayout>
			<Home homepageService={homepageService} collections={collections} dailyPuzzle={dailyPuzzle} />
		</DefaultLayout>
	);
};

export default makeHomepage;
