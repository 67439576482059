import { useCallback, useEffect } from 'react';
import { useContentDetailsModal } from '@presentation/context/ContentDetailsModalContextProvider';
import { usePageContext } from 'vike-react/usePageContext';

export const useShowContentDetailsModalBasedOnUrl = () => {
	const { routeParams } = usePageContext();

	const { showContentDetails } = useContentDetailsModal();

	const getContentIdFromParams = useCallback((): string | undefined => {
		// Handle /puzzle/:contentIdWithNiceName.
		if (routeParams.contentIdWithNiceName) {
			return routeParams.contentIdWithNiceName.split('-')[0];
		}

		// Handle /puzzle/:contentId/:niceName.
		if (routeParams.contentId) {
			return routeParams.contentId;
		}

		return undefined;
	}, [routeParams]);

	useEffect(() => {
		(async () => {
			const contentId = getContentIdFromParams();

			if (!contentId) {
				return;
			}

			showContentDetails(contentId, 'puzzle');
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps -- We need params only for initial load when there is a fresh page openModal.
	}, []);
};
