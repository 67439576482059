export { Layout };

import App from '@main/App';
import React, { type FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

const Layout: FC<{ children: React.ReactElement }> = ({ children }) => {
	return (
		<React.StrictMode>
			<BrowserRouter>
				<App>{children}</App>
			</BrowserRouter>
		</React.StrictMode>
	);
};
