import type { IPuzzlesRepository } from '@domain/usecases/puzzles/PuzzlesRepository';
import type { ICollectionsRepository } from '@domain/usecases/collections/CollectionsRepository';
import type { ContentItemWithCollectionsModel } from '@domain/models/ContentItemWithCollectionsModel';
import type { CollectionModel } from '@domain/models/CollectionModel';

export class ContentService {
	constructor(
		private readonly puzzlesRepository: IPuzzlesRepository,
		private readonly collectionsRepository: ICollectionsRepository
	) {}

	async getContentById(type: string, id: string): Promise<ContentItemWithCollectionsModel | null> {
		const puzzle = await this.puzzlesRepository.getPuzzleById(id);

		if (!puzzle) {
			return null;
		}

		let collections: CollectionModel[] = [];
		if (puzzle?.collectionsIds.length) {
			collections = await Promise.all(
				puzzle?.collectionsIds.map(
					(collectionId) => this.collectionsRepository.getById(collectionId) as Promise<CollectionModel>
				)
			);

			if (collections[0] && collections[1] && collections[0].imageSrc === collections[1].imageSrc) {
				collections[1].imageSrc = await this.collectionsRepository.getImageForCollection(collections[1].id, 1);
			}
		}

		return {
			...puzzle,
			collections,
		};
	}
}
