import type { ContentItemWithAuthorModel } from '@domain/models';
import type { HomepageCategoryItem, IConfigRepository } from '@domain/usecases/config/ConfigRepository';
import { DynamicCollections, type IPuzzlesRepository } from '@domain/usecases/puzzles/PuzzlesRepository';

export interface HomepageSectionContent {
	id: string;
	isDynamic: boolean;
	title: string;
	items: ContentItemWithAuthorModel[];
}

export class HomepageService {
	private DEFAULT_ITEMS_LIMIT = 10;

	constructor(
		private readonly puzzlesRepository: IPuzzlesRepository,
		private readonly configRepository?: IConfigRepository
	) {}

	async getDailyPuzzleForToday(): Promise<ContentItemWithAuthorModel | null> {
		return this.puzzlesRepository.getDailyPuzzle(new Date());
	}

	async getNextHomepageSectionsData(categories: HomepageCategoryItem[]): Promise<HomepageSectionContent[]> {
		return Promise.all(
			categories.map(async (category) => {
				const items = await this.getPuzzleForCategory(category);

				return {
					id: category.source,
					isDynamic: Object.values(DynamicCollections).includes(category.source as DynamicCollections),
					title: category.name,
					items,
				};
			})
		);
	}

	async getHomepageCollections(): Promise<HomepageCategoryItem[]> {
		if (!this.configRepository) {
			return [];
		}

		const listOfCollections = await this.configRepository.getListOfCategoriesForHomepage();

		return listOfCollections.filter(
			(collection) =>
				collection.type === 'collection' ||
				// We select only this dynamic categories because others are connected with logged user
				Object.values(DynamicCollections).includes(collection.source as DynamicCollections)
		);
	}

	private async getPuzzleForCategory(category: HomepageCategoryItem): Promise<ContentItemWithAuthorModel[]> {
		if (category.type === 'collection') {
			return this.puzzlesRepository.getPuzzlesByCollectionId(category.source, { limit: this.DEFAULT_ITEMS_LIMIT });
		} else if (Object.values(DynamicCollections).includes(category.source as DynamicCollections)) {
			return this.puzzlesRepository.getPuzzlesFromDynamicCollection(category.source as DynamicCollections);
		} else {
			return [];
		}
	}
}
