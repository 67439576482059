import React, { type FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import theme from '@presentation/styling/theme';
import { SmartBannerContextProvider } from '@presentation/context/SmartBannerContextProvider';
import { SnackbarProvider } from 'notistack';

import './config/i18n';
import 'react-alice-carousel/lib/alice-carousel.css';

const App: FC<{ children: React.ReactElement }> = ({ children }) => {

	return (
        <ThemeProvider theme={theme}>
            <SmartBannerContextProvider>
                <SnackbarProvider maxSnack={3}>
                    <CssBaseline />
                    {children}
                </SnackbarProvider>
            </SmartBannerContextProvider>
        </ThemeProvider>
	);
};

export default App;
