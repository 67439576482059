import React from 'react';
import type { FC } from 'react';
import { Header } from '@presentation/components/Header';
import { Footer } from '@presentation/components/Footer';
import { ContentDetailsModalContextProvider } from '@presentation/context/ContentDetailsModalContextProvider';
import { ModalContextProvider } from '@presentation/context/ModalContextProvider';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

import { MainContent } from './styles';

const DefaultLayout: FC<{ children: React.ReactElement }> = ({ children }) => {
	const { isOpen } = useSmartBanner();

	return (
		<ModalContextProvider>
			<ContentDetailsModalContextProvider>
				<Header />
				<MainContent isSmartBannerOpen={isOpen} maxWidth='xxl'>
					{children}
				</MainContent>
				<Footer />
			</ContentDetailsModalContextProvider>
		</ModalContextProvider>
	);
};

export default DefaultLayout;
