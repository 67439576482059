import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeApp, getApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

import firebase, { EnvironmentType } from '../../config/firebase';

const firebaseConfig = {
	apiKey: firebase.API_KEY,
	authDomain: firebase.AUTH_DOMAIN,
	projectId: firebase.PROJECT_ID,
	storageBucket: firebase.STORAGE_BUCKET,
	messagingSenderId: firebase.MESSAGING_SENDER_ID,
	appId: firebase.APP_ID,
};

const createFirebaseApp = () => {
	try {
		return getApp();
	} catch (error) {
		const app = initializeApp(firebaseConfig);

		// NOTE: debug token will be automatically added if it is the local environment
		// same debug token is used for all developers (no need for refreshing tokens
		// because the debug token will be only used on the local and dev environment)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (typeof window !== 'undefined') {
			(self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = firebase.FIREBASE_APPCHECK_DEBUG_TOKEN;

			initializeAppCheck(app, {
				provider: new ReCaptchaEnterpriseProvider(firebase.RECAPTCHA_KEY),
				isTokenAutoRefreshEnabled: true,
			});
		}

		console.log('Firebase app initialized');

		return app;
	}
};

export const app = createFirebaseApp();

export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

let remoteConfig;
if (typeof window !== 'undefined') {
	remoteConfig = getRemoteConfig(app);

	if (firebase.ENVIRONMENT === EnvironmentType.DEVELOPMENT) {
		remoteConfig.settings.minimumFetchIntervalMillis = 0;
	} else if (firebase.ENVIRONMENT === EnvironmentType.PRODUCTION) {
		remoteConfig.settings.minimumFetchIntervalMillis = 30 * 60 * 1000; // 30 minutes.
	}
}

export { remoteConfig };

if (firebase.ENVIRONMENT === EnvironmentType.EMULATOR) {
	connectAuthEmulator(auth, 'http://localhost:9099');
	connectFunctionsEmulator(functions, 'localhost', 5001);
	connectFirestoreEmulator(firestore, 'localhost', 8080);
}
