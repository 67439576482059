import { httpsCallable } from 'firebase/functions';
import { firestore, functions } from '@infrastructure/firebase';
import type { SimplePuzzleDto } from '@infrastructure/firebase/firestore/dto/SimplePuzzleDto';
import {
	DynamicCollections,
	type IPuzzlesRepository,
	type ListOptions,
} from '@domain/usecases/puzzles/PuzzlesRepository';
import type { ContentItemModel, ContentItemWithAuthorModel, CreatorModel } from '@domain/models';
import {
	collection,
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	startAfter,
	where,
} from 'firebase/firestore';
import type { PuzzleDBO } from '@infrastructure/firebase/firestore/dto/PuzzleDBO';
import { PuzzleSortField } from '@domain/usecases/puzzles/PuzzleSortField';
import { FirestorePuzzleRepository } from '@infrastructure/firebase/firestore/FirestorePuzzleRepository';
import type { AuthorDBO } from '@infrastructure/firebase/firestore/dto/AuthorDBO';

export class FirestoreClientPuzzleRepository extends FirestorePuzzleRepository implements IPuzzlesRepository {
	async getDailyPuzzle(date: Date): Promise<ContentItemWithAuthorModel | null> {
		const collectionRef = collection(firestore, `/daily_puzzles`);
		const queryRef = query(collectionRef, where('date', '<=', date), orderBy('date', 'desc'), limit(1));

		const querySnapshot = await getDocs(queryRef);
		const document = querySnapshot.docs?.[0]?.data();

		if (!document) {
			return null;
		}

		const puzzle = await this._getPuzzleById(document.puzzleId);
		if (!puzzle) {
			return null;
		}

		const author = await this.getAuthor(puzzle.Publisher);

		const puzzleModel = this.puzzleDBOToContentItem(document.puzzleId, puzzle);

		return {
			...puzzleModel,
			author,
		};
	}

	private async _getPuzzleById(id: string): Promise<PuzzleDBO | null> {
		const puzzleRef = doc(firestore, 'puzzles', id);
		const puzzle = await getDoc(puzzleRef);

		if (!puzzle.exists() || puzzle.data().status !== 'approved') {
			return null;
		}

		return puzzle.data() as PuzzleDBO;
	}

	async getPuzzleById(id: string): Promise<ContentItemWithAuthorModel | null> {
		const puzzleDBO = await this._getPuzzleById(id);

		if (!puzzleDBO) {
			return null;
		}

		const author = await this.getAuthor(puzzleDBO.Publisher);
		const puzzleModel = this.puzzleDBOToContentItem(id, puzzleDBO);

		return {
			...puzzleModel,
			author,
		};
	}

	async countPuzzlesByPublisher(publisherId: string): Promise<number> {
		const collectionRef = collection(firestore, `/puzzles`);
		const queryRef = query(
			collectionRef,
			where('Publisher', '==', publisherId),
			where('status', '==', 'approved'),
			orderBy('createdAt', 'desc')
		);

		const querySnapshot = await getCountFromServer(queryRef);

		return querySnapshot.data().count;
	}

	async getPuzzlesByPublisher(publisherId: string, options: ListOptions): Promise<ContentItemModel[]> {
		let lastRef;
		if (options.last) {
			lastRef = await getDoc(doc(firestore, `/puzzles`, options.last));
		}

		const collectionRef = collection(firestore, `/puzzles`);
		const queryRef = query(
			collectionRef,
			where('Publisher', '==', publisherId),
			where('status', '==', 'approved'),
			orderBy(options.order?.field ?? PuzzleSortField.publishedAt, options.order?.direction ?? 'desc'),
			...(lastRef ? [startAfter(lastRef)] : []),
			limit(options.limit)
		);

		const querySnapshot = await getDocs(queryRef);
		return Promise.all(
			querySnapshot.docs.map(async (document) => {
				return this.puzzleDBOToContentItem(document.id, document.data() as PuzzleDBO);
			})
		);
	}

	async countPuzzlesByCollection(collectionId: string): Promise<number> {
		const collectionRef = collection(firestore, `/puzzles`);
		const queryRef = query(
			collectionRef,
			where('collections', 'array-contains', collectionId),
			where('status', '==', 'approved'),
			orderBy('createdAt', 'desc')
		);

		const querySnapshot = await getCountFromServer(queryRef);

		return querySnapshot.data().count;
	}

	async getPuzzlesByCollectionId(collectionId: string, options: ListOptions): Promise<ContentItemWithAuthorModel[]> {
		let lastRef;
		if (options.last) {
			lastRef = await getDoc(doc(firestore, `/puzzles`, options.last));
		}

		const collectionRef = collection(firestore, `/puzzles`);
		const queryRef = query(
			collectionRef,
			where('collections', 'array-contains', collectionId),
			where('status', '==', 'approved'),
			orderBy(options.order?.field ?? PuzzleSortField.publishedAt, options.order?.direction ?? 'desc'),
			...(lastRef ? [startAfter(lastRef)] : []),
			limit(options.limit)
		);

		const querySnapshot = await getDocs(queryRef);
		return Promise.all(
			querySnapshot.docs.map(async (document) => {
				const puzzleModel = this.puzzleDBOToContentItem(document.id, document.data() as PuzzleDBO);

				return {
					...puzzleModel,
					author: await this.getAuthor(puzzleModel.authorId),
				};
			})
		);
	}

	async getPuzzlesFromDynamicCollection(category: DynamicCollections): Promise<ContentItemWithAuthorModel[]> {
		const getPuzzles = httpsCallable<any, any>(functions, 'jpu-homeScreen-getPuzzles');

		const items = (await getPuzzles({ endpoint: category })).data;

		return Promise.all(
			JSON.parse(items).map(async (item: SimplePuzzleDto) => ({
				id: item.id,
				name: item.name,
				imageSrc: item.videoIconUrl || item.iconUrl,
				author: item.publisher ? await this.getAuthor(item.publisher) : {},
			}))
		);
	}

	private async getAuthor(id: string): Promise<CreatorModel | null> {
		if (!id) {
			return null;
		}
		const creatorRef = doc(firestore, 'usersWeb', id);
		const creator = await getDoc(creatorRef);

		const creatorData = creator.data();

		return this.creatorDBOToCreatorModel(id, creatorData as AuthorDBO);
	}
}
