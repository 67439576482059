import { createTheme } from '@mui/material/styles';
export default createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1507,
			xxl: 1710,
		},
	},
	shadow: {
		iconButton: '0px 11px 15px -7px #00000033',
	},
	colors: {
		background: '#fff', // Pure white - mostly used for card background colors
		darkerBackground: '#EAEAEA',
		black: '#000',
		border: '#D2D2D2', // Gray-ish - used for borders
		text: '#111111DE', // Black color
		descriptionText: '#11111199', // Hard gray
		link: '#2196F3',
		brand: '#2196F3', // Brend blue
		cardTextColor: '#FFF',
		menuLink: '#7a7a7a',
	},
	typography: {
		fontSize: 16,
		h1: {
			fontWeight: 'bold',
			color: '#fff',
		},
		h2: {
			fontSize: 18,
			fontWeight: 600,
			color: '#2F2F2F',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		h3: {
			fontSize: 40,
			fontWeight: 'bold',
			color: '#fff',
		},
		h4: {
			fontSize: 24,
			fontWeight: '600',
			color: '#3F3F3F',
		},
		h5: {
			fontSize: 16,
			fontWeight: 600,
			color: '#F1F3F8',
		},
		body1: {
			fontSize: 12,
			fontWeight: 400,
			color: '#303030',
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: 400,
			color: '#7e7e7e',
		},
		subtitle2: {
			fontSize: 12,
			fontWeight: 400,
			color: '#F1F3F8',
		},
		subtitle3: {
			fontSize: 20,
			fontWeight: 600,
			color: '#3F3F3F',
		},
		subtitle2Uppercase: {
			fontSize: 12,
			fontWeight: 400,
			color: '#F1F3F8',
			textTransform: 'uppercase',
		},
		smartBannerTitle: {
			fontSize: 13,
			fontWeight: 600,
			color: '#000',
		},
		smartBannerSubtitle: {
			fontSize: 11,
			fontWeight: 300,
			color: '#6a6a6a',
		},
		link: {
			fontSize: 12,
			fontWeight: 400,
			color: '#2196F3',
		},
	},
	palette: {
		primary: {
			main: '#303030',
		},
		secondary: {
			main: '#7a7a7a',
		},
		buttonBlue: {
			main: '#2196F3',
			contrastText: '#fff',
		},
	},
	margin: {
		normal: 15,
	},
	padding: {
		big: 24,
		normal: 16,
		small: 8,
	},
	sizes: {
		bannerImage: {
			maxWidth: 198,
		},
		avatar: {
			small: 50,
		},
		contentModal: {
			maxWidth: 450,
			footerHeight: 105,
		},
		header: {
			xxl: 90,
			lg: 90,
			md: 60,
		},
		smartBanner: {
			height: 80,
		},
		headerWidthSmartBanner: {
			xxl: 160,
			lg: 160,
			md: 140,
		},
	},
});
